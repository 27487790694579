// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';

#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#app main {
     flex-grow: 1;
}

footer {
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.view-action-group {
    margin-bottom: 1rem;

    & > :not(:first-of-type) {
        margin-left: 5px;
    }
}


.tooltip .tooltip-inner {
    text-align: left;
    white-space: pre-line;
}

.popover.fit-to-child {
    max-width: none;
}

dd {
    min-height: $line-height-base * 1em;
}

.card .table:last-child {
    margin-bottom: 0;
}

#device-show #section-uploads .pagination {
    margin-bottom: 0;
}

.tac {
    text-align: center;
}

.collapse-marker {
    float: right;

    &::before {
        content: "▾";
    }

    [data-toggle="collapse"].collapsed &::before {
        content: "◂";
    }
}
